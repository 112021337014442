import GolfErpAPI from "@/api/v2/GolfErpAPI";
export const ROOT_PATH = '/stock-facility-std';

class StockFacilityStd {

    /** 시설 리스트 조회 */
    async getFcltyList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/fclty/list` ,{params} );
        return data;
    }
    /** 시설 상세 조회 */
    async getFcltyDetail(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/fclty/detail` ,{params} );
        return data;
    }
    /** 시설 등록 */
    async postFcltyDetail(params) {
        const {data} = await GolfErpAPI.http.post(`${ROOT_PATH}/fclty/detail` ,params );
        return data;
    }
    /** 시설 수정 */
    async patchFcltyDetail(params) {
        const {data} = await GolfErpAPI.http.patch(`${ROOT_PATH}/fclty/detail` ,params );
        return data;
    }
    /** 시설 스펙 조회 */
    async getFcltySpecList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/fclty/spec` ,{params} );
        return data;
    }
    /** 시설 수리이력 조회 */
    async getFcltyRepairList(params) {
        const {data} = await GolfErpAPI.http.get(`${ROOT_PATH}/fclty/repair` ,{params} );
        return data;
    }

}

export default new StockFacilityStd();