<template>
  <div class="content-wrapper">
    <!-- REPORT -->
    <report-view ref="reportViewComponent" :isPopup="true"/>
    <!-- 필터영역 -->
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">시설명</div>
            <ul class="content">
              <li class="item">
                <input-text ref="searchFcltyName" v-model="searchFilterOptions.fcltyName" @keydown.enter="onClickSearch"/>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">시설구분</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    ref="searchFcltyDiv"
                    v-model="searchFilterOptions.fcltyDiv"
                    :dataSource="commonCodeOptions.fcltyDiv"
                    :allowFiltering="false"
                    :fields="commonCodeOptions.fields"
                    cssClass="lookup-condition-dropdown"
                >
                </ejs-dropdownlist>
              </li>
            </ul>
          </li>
          <li class="field sales">
            <div class="title">상태</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    ref="searchFcltyStatus"
                    v-model="searchFilterOptions.fcltyStatus"
                    :dataSource="commonCodeOptions.fcltyStatus"
                    :allowFiltering="false"
                    :fields="commonCodeOptions.fields"
                    cssClass="lookup-condition-dropdown"
                >
                </ejs-dropdownlist>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button button-div="GET" :is-shortcut-button="true" :ignore="isPopupOpened" @click.native="onClickSearch">조회</erp-button>
        </div>
      </div>
    </div>
    <!-- 본문 -->
    <div class="content-body">
      <article class="body-article" :class="isOpen ? 'detail-view-opened' : ''">
        <div class="article-left">
          <section class="article-section">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">{{gridField.title}}</div>
                <div class="header-caption">[ {{ gridField.count }} 건 ]</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="add">
                    <erp-button button-div="GET" :is-icon-custom="true" @click.native="onClickAdd">추가</erp-button>
                  </li>
                  <li class="print">
                    <erp-button button-div="FILE" @click.native="onClickExcel">Excel</erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <div class="body-grid">
                <ejs-grid-wrapper
                    ref="grid"
                    v-bind ="initGrid"
                    @recordClick="onGridClick"
                    @queryCellInfo="onQueryCellInfo"
                    @headerCellInfo="onHeaderCellInfo"
                    @actionComplete="gridComplete"/>
              </div>
            </div>
          </section>
        </div>
        <!-- ######## 상세본문 ########-->
        <div class="article-right">
          <section class="article-section" >
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">시설상세</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="save keyColor">
                    <erp-button button-div="SAVE" :is-key-color="true" :is-shortcut-button="true" :ignore="!isOpen || isPopupOpened" @click.native="onClickSave">저장</erp-button>
                  </li>
                  <li class="delete">
                    <erp-button v-if="!isNew" button-div="DELETE" :is-key-color="true" :is-shortcut-button="true" :ignore="!isOpen || isPopupOpened" @click.native="onClickDelete">삭제</erp-button>
                  </li>
                  <li class="close">
                    <erp-button button-div="CLOSE" @click.native="onClickClose">닫기</erp-button>
                  </li>
                </ul>
              </div>
            </div>

            <div class="section-body">
              <div class="article-section" style="height: 360px;">
                <div class="section-body">
                  <div class="article-left" style="width: 25%;">
                    <section class="article-section">
                      <div class="section-header">
                        <div class="header-left">
                          <div class="header-title">사진</div>
                        </div>
                        <div class="header-right">
                          <ul class="header-button">
                              <li class="registration">
                                <ejs-uploader
                                    ref="fileUploader"
                                    name="fileUploader"
                                    maxFileSize="10000000"
                                    :buttons="{ browse: '등록' }"
                                    :allowedExtensions="imageFileUploaderExtensions"
                                    :multiple="false"
                                    :selected="onFileSelect"
                                ></ejs-uploader>
                              </li>
                            <li class="delete">
                              <erp-button button-div="DELETE" :is-icon-custom="true" @click.native="onDeleteImage">삭제</erp-button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="section-body" style="border: none;">
                        <div class="article-left" style="width: 100%;">
                          <div class="section-body" style="border: none;">
                            <div class="body-photo">
                              <div
                                  v-if="detailData.imagePath"
                                  id="previewImage"
                                  class="item"
                                  :style="{ backgroundImage:'url('+detailData.imagePath+')' }"
                              ></div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                <div class="article-left" style="width: 35%;">
                  <section class="article-section">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">기본정보</div>
                      </div>
                    </div>
                    <div class="section-body" style="border: none;">
                      <div class="article-right" style="width: 100%;">
                        <div class="section-body" style="border: none;">
                          <article class="article body">
                              <div class="body-data">
                                <div class="data-outer">
                                  <div class="data-inner">
                                    <ul class="data-content">
                                      <li class="field voc-section">
                                        <div class="title">시설코드</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-text ref="fcltyCode" id="fcltyCode" v-model="detailData.fcltyCode" :readonly="true"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title required">시설명</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-text ref="fcltyName" id="fcltyName" v-model="detailData.fcltyName"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title required">시설구분</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <ejs-dropdownlist
                                                    ref="fcltyDiv"
                                                    v-model="detailData.fcltyDiv"
                                                    :dataSource="commonCodeOptions.fcltyDiv"
                                                    :allowFiltering="false"
                                                    :fields="commonCodeOptions.fields"
                                                    cssClass="body-data-dropdown"
                                                />
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title required">상태</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <ejs-dropdownlist
                                                    ref="fcltyStatus"
                                                    if="fcltyStatus"
                                                    v-model="detailData.status"
                                                    :dataSource="commonCodeOptions.fcltyStatus"
                                                    :allowFiltering="false"
                                                    :fields="commonCodeOptions.fields"
                                                    cssClass="body-data-dropdown"
                                                />
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title">구입일자</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-date format="YYYY-MM-DD" ref="purDate" id="purDate"  v-model="detailData.purchsDate" :isBindingEmptyString="true" type="body-data"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title">폐기일자</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-date format="YYYY-MM-DD" ref="dsuDate" id="dsuDate" v-model="detailData.dsuseDate" :isBindingEmptyString="true" type="body-data"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title">제조사</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-text ref="makr" id="makr" v-model="detailData.makr"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title">규격</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-text ref="standard" id="standard" v-model="detailData.standard"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field voc-section">
                                        <div class="title">가격</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-number ref="price" name="price" v-model="detailData.price"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li class="field remarks voc-section">
                                        <div class="title">비고</div>
                                        <div class="content">
                                          <ul class="row">
                                            <li class="item form-group">
                                              <div class="form">
                                                <input-textarea ref="remarks" name="remarks" v-model="detailData.remarks"/>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </article>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div class="article-right" style="width: 40%;">
                    <section class="article-section">
                      <div class="section-header">
                        <div class="header-left">
                          <div class="header-title">스펙</div>
                        </div>
                        <div class="header-right">
                          <ul class="header-button">
                            <li class="save keyColor">
                              <erp-button button-div="GET" ref="specAdd" :is-icon-custom="true" @click.native="onClickSpecAdd">추가</erp-button>
                            </li>
                            <li class="delete">
                              <erp-button button-div="DELETE" ref="specDel" @click.native="onClickSpecDel">삭제</erp-button>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="section-body" style="border: none;">
                        <div class="body-grid">
                          <ejs-grid-wrapper
                              ref="grid2"
                              v-bind ="initGrid2"
                              @queryCellInfo="onQueryCellInfo2"
                              @headerCellInfo="onHeaderCellInfo2"
                              @actionComplete="gridComplete2"/>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <div class="article-section" style="height:calc(100% - 360px)">
                <div class="section-body">
                  <div class="article-section">
                    <div class="section-header">
                      <div class="header-left">
                        <div class="header-title">수리이력</div>
                        <div class="header-caption">[ {{ gridField3.count }} 건 ]</div>
                      </div>
                      <div class="header-right">
                        <ul class="header-button">
                          <li class="print">
                            <erp-button v-if="!isNew" button-div="FILE" @click.native="onClickExcel3">Excel</erp-button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="section-body" style="border: none;">
                      <div class="body-grid">
                        <ejs-grid-wrapper
                            ref="grid3"
                            v-bind ="initGrid3"
                            @actionComplete="gridComplete3"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>
    </div>
    <!-- 팝업 -->
  </div>
</template>

<script>

import commonMixin from "@/views/layout/mixin/commonMixin";
import ReportView from "@/components/common/report/ReportView.vue";
import ErpButton from "@/components/button/ErpButton.vue";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import EjsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {ExcelExport, Filter, ForeignKey, Page, Resize, Edit} from "@syncfusion/ej2-vue-grids";
import MessagePopupDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import {commonCodesGetCommonCode} from "@/utils/commonCodes";
import InputText from "@/components/common/text/InputText.vue";
import InputDate from "@/components/common/datetime/InputDate.vue";
import InputNumber from "@/components/common/InputNumber.vue";
import InputTextarea from "@/components/common/text/InputTextarea.vue";

import {getFormattedDate} from "@/utils/date";
import {uploadImage, validateFormRefs} from "@/utils/formUtil";
import StockFacilityStd from "@/api/v2/StockManage/StockFacilityStd";

export default {
  name: "FacilityCodeRegistration",
  mixins: [commonMixin, MessagePopupDialogMixin],
  components: {EjsGridWrapper, ErpButton, ReportView,
    InputText,
    InputDate,
    InputNumber,
    InputTextarea,
  },

  /**
   * ##################################### 데이터 필드 정의 ########################################
   */
  data() {
    return {
      //신규,수정 여부
      isNew: false,
      //팝업 오픈 여부
      isOpen: false,
      //이미지 등록 후 데이터 최종 저장여부
      isImageUploadedAndNotSaved: false,
      //이미지 확장자
      imageFileUploaderExtensions: ".png, .jpg, .jpeg, .gif, .bmp",

      //조회 필드
      searchFilterOptions: {
        fcltyName: "",
        fcltyDiv: "",
        fcltyStatus: "",
      },
      //드롭다운 코드
      commonCodeOptions: {
        fcltyDiv: commonCodesGetCommonCode("FCLTY_DIV",false,true,"",""),
        fcltyStatus: commonCodesGetCommonCode("FCLTY_STATUS",false,true,"",""),
        fcltyChckPart: commonCodesGetCommonCode("FCLTY_CHCK_PART",false,true,"",""),
        fcltyRepairPart: commonCodesGetCommonCode("FCLTY_REPAIR_PART",false,true,"",""),
        fcltyDefectRegn: commonCodesGetCommonCode("FCLTY_DEFECT_REGN",false,true,"",""),
        fields: { text: "comName", value: "comCode" },
      },
      //정합성 체크 항목
      validateRefField: {
        fcltyName: { required: true },
        fcltyDiv: { required: true },
        fcltyStatus: { required: true},
      },

      //그리드 필드
      gridField: {
        title: "시설목록",
        count : 0,
        dataSource: [],
      },
      gridField2: {
        title: "스펙",
        count : 0,
        dataSource: [],
      },
      gridField3: {
        title: "수리이력",
        count : 0,
        dataSource: [],
      },

      //상세 데이터 필드
      detailData : {
        fcltyCode: null,
        fcltyName: null,
        fcltyDiv: null,
        imagePath: null,
        imageName: null,
        status: "S",
        purchsDate: null,
        dsuseDate: null,
        makr: null,
        standard: null,
        price: 0,
        remarks: null,
        useFlag: true,
        //스펙 저장 리스트
        specListD: null,
        specListU: null,
        specListI: null,
      },
    };
  },

  /**
   * ##################################### 그리드 정의 ########################################
   */
  computed: {
    // 팝업시 메인화면 버튼의 단축키 조정 , 팝업이 없으면 false 리턴
    isPopupOpened() {
      return false;
    },
    initGrid() {
      return {
        columns: [
          { field: "fcltyCode",  textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",   headerText: "시설코드", isPrimaryKey: true, },
          { field: "fcltyName",  textAlign: 'left',   allowEditing: false, width: 200,  visible: true,  type: "string",   headerText: "시설명", },
          { field: "fcltyDiv",   textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",   headerText: "시설구분", isCommonCodeField: true, dataSource: this.commonCodeOptions.fcltyDiv},
          { field: "status",     textAlign: 'center', allowEditing: false, width: 100,  visible: false, type: "string",   headerText: "상태",    isCommonCodeField: true, dataSource: this.commonCodeOptions.fcltyDiv},
          { field: "purchsDate", textAlign: 'center', allowEditing: false, width: 100,  visible: true,  type: "string",  headerText: "구입일자",
            valueAccessor: (field,data) => getFormattedDate(data[field]) },
          { field: "dsuseDate",  textAlign: 'center', allowEditing: false, width: 100,  visible: false, type: "string",  headerText: "폐기일자",
            valueAccessor: (field,data) => getFormattedDate(data[field]) },
          { field: "makr",       textAlign: 'center', allowEditing: false, width: 150,  visible: true,  type: "string",  headerText: "제조사", },
          { field: "standard",   textAlign: 'left',   allowEditing: false, width: 100,  visible: false, type: "string",  headerText: "규격", },
          { field: "price",      textAlign: 'right',  allowEditing: false, width: 100,  visible: false, type: "number",  headerText: "가격", isNumericType: true},
          { field: "remarks",    textAlign: 'left',   allowEditing: false, width: 250,  visible: true,  type: "string",  headerText: "비고", },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: true,
        pageSettings: { pageSize: 50 },
        resizeSettings: {mode:"Normal"},
      };
    },
    initGrid2() {
      return {
        columns: [
          { field: "specId",    textAlign: 'center', allowEditing: false, width: 30,  visible: false, type: "string",  headerText: "사양ID", isPrimaryKey: true,  },
          { field: "fcltyCode", textAlign: 'center', allowEditing: false, width: 30,  visible: false, type: "string",  headerText: "시설코드", },
          { field: "sortNo",    textAlign: 'right',  allowEditing: true , width: 30,  visible: true,  type: "number",  headerText: "순번", },
          { field: "specName",  textAlign: 'left',   allowEditing: true,  width: 100, visible: true,  type: "string",  headerText: "사양명", },
          { field: "specValue", textAlign: 'left',   allowEditing: true,  width: 150, visible: true,  type: "string",  headerText: "사양값", },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField2.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:false,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: false,
        pageSettings: { pageSize: 50 },
        validationRules: {
          sortNo:    {required: true, duplicateCheck: true, maxLength: 3,},
          specName:  {required: true,},
          specValue: {required: true,},
        },
      };
    },
    initGrid3() {
      return {
        columns: [
          { field: "repairId",       textAlign: 'center', allowEditing: false, width: 30,  visible: false, type: "string",  headerText: "수리ID", isPrimaryKey: true,  },
          { field: "fcltyCode",      textAlign: 'center', allowEditing: false, width: 30,  visible: false, type: "string",  headerText: "시설코드", },
          { field: "repairDate",     textAlign: 'center', allowEditing: false, width: 80,  visible: true,  type: "string",  headerText: "수리일자",
            valueAccessor: (field,data) => getFormattedDate(data[field]) },
          { field: "chckPart",       textAlign: 'center', allowEditing: false, width: 80,  visible: true, type: "string",  headerText: "점검부",    isCommonCodeField: true, dataSource: this.commonCodeOptions.fcltyChckPart},
          { field: "repairDiv",      textAlign: 'center', allowEditing: false, width: 80,  visible: true, type: "string",  headerText: "수리구분",   isCommonCodeField: true, dataSource: this.commonCodeOptions.fcltyRepairPart},
          { field: "defectRegn",     textAlign: 'center', allowEditing: false, width: 80,  visible: true, type: "string",  headerText: "고장부위",   isCommonCodeField: true, dataSource: this.commonCodeOptions.fcltyDefectRegn},
          { field: "remarks",        textAlign: 'Left',   allowEditing: false, width: 100, visible: true,  type: "string",  headerText: "수리내역", },
        ],
        provides: [ExcelExport, Filter, ForeignKey, Page, Resize, Edit],
        dataSource: this.gridField3.dataSource,

        allowResizing: true,
        allowSorting: true,
        allowFiltering: true,
        allowExcelExport: true,
        isShowProgress: true,
        isNOColumnDisplay:true,
        noColumnWidth: 35,
        isSelectedRowRetain: true,
        selectionSettings: {type: "Single", mode: "Row", enableToggle: false, persistSelection: false,},
        allowPaging: true,
        pageSettings: { pageSize: 50 },
      };
    },

  },
  /**
   * ##################################### 화면 시작시 DATA API ########################################
   */
  created() {
    // 기초 데이터 초기화
    this.clearDetailData();
  },
  async mounted() {
    // 기본 포커싱
    this.$refs.searchFcltyName.focusIn();
  },
  //닫기시 저장안되어 있으면 파일 삭제
  async beforeDestroy() {
    if (this.isImageUploadedAndNotSaved) {
      await this.onDeleteImage();
    }
  },
  /**
   * ##################################### 화면 이벤트 ########################################
   */
  methods: {
    //필수입력체크
    validateFormRefs,
    //region******************************* 버튼 이벤트 *******************************
    //조회
    async onClickSearch() {
      await this.onClickClose();
      this.gridField.dataSource = await StockFacilityStd.getFcltyList(this.searchFilterOptions);
    },
    //추가
    async onClickAdd() {
      this.isOpen = true;
      this.isNew = true;
      await this.clearDetailData();
    },
    //저장
    async onClickSave() {
      //1.필수입력체크
      if (!this.validateFormRefs(this.validateRefField)) {
        return;
      }
      //2. 그리드 필수 입력체크
      const {addedRecords, changedRecords, deletedRecords,} = this.$refs.grid2.getBatchChanges();
      if (this.$refs.grid2.isGridModified() && !this.$refs.grid2.validate()) {
        return;
      }
      this.detailData.specListD = deletedRecords;
      this.detailData.specListU = changedRecords;
      this.detailData.specListI = addedRecords;
      //3. 저장처리
      if(this.isNew) {
        await StockFacilityStd.postFcltyDetail(this.detailData);
      }else{
        await StockFacilityStd.patchFcltyDetail(this.detailData);
      }

      //이미지업로드후 미저장여부
      this.isImageUploadedAndNotSaved = false;

      //4. 메세지 호출
      this.infoToast(this.$t("main.popupMessage.saved"));
      await this.onClickSearch();
    },

    //삭제
    async onClickDelete() {
      if (!(await this.confirm(this.$t('main.popupMessage.confirmDelete')))) {
        return;
      }
      //1. 저장처리
      this.detailData.useFlag = false;
      await StockFacilityStd.patchFcltyDetail(this.detailData);
      //2. 메세지 호출
      this.infoToast(this.$t('main.popupMessage.deleted'));
      await this.onClickSearch();
    },
    //엑셀 출력
    onClickExcel() {
      this.$refs.grid.excelExport({fileName: this.gridField.title+".xlsx"});
    },
    onClickExcel3() {
      const fcltyNm = this.detailData.fcltyName;
      if(fcltyNm?.trim()){
        this.$refs.grid3.excelExport({fileName: fcltyNm+" 수리이력.xlsx"});
      }
    },
    //닫기
    async onClickClose() {
      this.isOpen = false;
      await this.clearDetailData();
    },
    //리포트 출력
    onClickPrint() {
    },
    //endregion
    //region******************************* 이미지 첨부  *******************************
    async onFileSelect(args) {
      const file = args.event.target.files[0];
      try {
        const uploadResponse = await uploadImage(
            this.detailData.imageId,
            "FCL",
            args.filesData,
            file
        );
        if (uploadResponse) {
          this.detailData.imageId = uploadResponse.imageId;
          this.detailData.imagePath = uploadResponse.imageURL;
          this.isImageUploadedAndNotSaved = true;
        }
      } catch (e) {
        alert(e.message);
      } finally {
        this.$refs.fileUploader.clearAll(); // 다시 upload 하지 않도록 clear
      }
    },

    async onDeleteImage() {
      this.$refs.fileUploader.clearAll();
      try {
        if (this.detailData.imageId){
          await GolfErpAPI.deleteImage(this.detailData.imageId);
        }
        this.detailData.imageId = null;
        this.detailData.imagePath = null;
        this.isImageUploadedAndNotSaved = false;
      } catch (e) {
        console.error("imageDelete.err.===>", e);
      }
    },
    //endregion
    //region******************************* 상세 이벤트 *******************************
    //장비 상세 조회
    async searchDetail(code) {
      const args = { fcltyCode : code};
      this.detailData = await StockFacilityStd.getFcltyDetail(args);
    },
    //스펙 조회
    async searchSpec(code) {
      const args = { fcltyCode : code};
      this.gridField2.dataSource = await StockFacilityStd.getFcltySpecList(args);
    },
    //스펙 추가
    onClickSpecAdd() {
      this.$refs.grid2.addRecord({
        specId: "",
        fcltyCode: this.detailData.fcltyCode,
        specName: "",
        specValue: "",
      });
    },
    //스펙 삭제
    onClickSpecDel() {
      return this.$refs.grid2.deleteRecord();
    },
    //수리 목록 조회
    async searchRepair(code) {
      const args = { fcltyCode : code};
      this.gridField3.dataSource = await StockFacilityStd.getFcltyRepairList(args);
    },
    //endregion
    //region******************************* 공통 이벤트 *******************************
    async clearDetailData() {
      //이미지 등록후 저장안되어있으면 이미지 삭제
      if (this.isImageUploadedAndNotSaved) {
        await this.onDeleteImage();
      }
      this.detailData.fcltyCode = null;
      this.detailData.fcltyName = null;
      this.detailData.fcltyDiv = null;
      this.detailData.imagePath = null;
      this.detailData.imageId = null;
      this.detailData.status = "S";
      this.detailData.purchsDate = getFormattedDate(new Date());
      this.detailData.dsuseDate = null;
      this.detailData.makr = null;
      this.detailData.standard = null;
      this.detailData.price = null;
      this.detailData.remarks = null;
      this.detailData.useFlag = true;
      this.gridField2.dataSource = [];
      this.gridField3.dataSource = [];
    },
    //endregion
    //region******************************* 그리드 이벤트 *******************************
    //그리드 헤더 이미지적용
    onHeaderCellInfo(args){
      const {cell: {column:{field}}, node} = args;
      if(this.$refs.grid.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
      if (['fcltyName'].includes(field)) {
        node.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    onHeaderCellInfo2(args){
      const {cell: {column:{field}}, node} = args;
      if(this.$refs.grid2.validationRules[field]?.required){
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    //그리드 셀 이미지 적용
    onQueryCellInfo(args){
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
      if (['fcltyName'].includes(field)) {
        cell.classList.add(this.$t('className.grid.clickArea'));
      }
    },
    onQueryCellInfo2(args){
      const {column:{field,allowEditing}, cell} = args;
      if(allowEditing && field){
        cell.classList.add(this.$t('className.grid.modifyArea'));
      }
    },
    //그리드 셀 클릭
    async onGridClick(args){
      const {column:{field}, rowData} = args;
      if(field === "fcltyName"){
        this.isOpen = true;
        this.isNew = false;

        //상세 clear
        await this.clearDetailData();
        //상세 표시
        await this.searchDetail(rowData.fcltyCode);
        //스펙 표시
        await this.searchSpec(rowData.fcltyCode);
        //수리내역 표시
        await this.searchRepair(rowData.fcltyCode);
        this.$refs["fcltyName"].$el.focus();
      }
    },
    //그리드 조회 완료시(카운트)
    gridComplete(){
      this.gridField.count = this.$refs.grid.getGridBatchCount();
    },
    gridComplete2(){
      this.gridField2.count = this.$refs.grid2.getGridBatchCount();
    },
    gridComplete3(){
      this.gridField3.count = this.$refs.grid3.getGridBatchCount();
    },
  }
};
</script>

<!-- ##################################### CUSTOM STYLE ########################################* -->
<style scoped>
body .appContent .body-article .article-left,body .appContent .body-article .article-right{transition:all .3s ease-out}
body .appContent .body-article .article-left{width:100%;}
body .appContent .body-article .article-right{margin-right: calc(-50% - 20px)}
body .appContent .body-article.detail-view-opened .article-left{width:calc(30% - 0px)}
body .appContent .body-article.detail-view-opened .article-right{width:calc(70% - 0px); margin:0}
body .body-photo {height :270px}
body .body-footer .section-header {display: block;position: relative;margin: 0px 0px;}
.voc-section {width: 100% !important;}
</style>
